.about-us{
    /* margin-bottom: 60px; */
    position: relative;
    background:url('/src/assets/imgs/contactUsBG.png') ,#000000;
    background-size: 101% 100%;
    padding:100px 0;
    height: 100vh;
    /* scroll-snap-align: start;
    height:100vh */
}
.about-us__content-wrapper{
    width:80%
}
.about-us__sub-title{
    font-size: 15px;
    color:var(--main-color);
    margin-bottom: 32px;
}
.about-us__title{
    color:white;
    font-size: 22px;
    line-height: 33px;
    font-family: 'PoppinsSemiBold';
    margin-bottom: 10px;
}
.about-us__description{
    color:#C3C3C3;
    font-size: 13px;
    line-height: 21.97px;
    margin-bottom: 30px;
}
.about-us__features-wrapper{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap:30px;
    margin-bottom: 20px;
}
.about-us__feature-wrapper{
    display: flex;
    flex-direction: column;
    gap:7px;
}
.about-us__feature-icon{
    width:40px;
    width:fit-content;
    height:40px;
    object-fit: contain;
}
.about-us__feature-text{
    color:white;
    font-size: 13px;
    line-height: 16.9px;
}
.about-us__img{
    width:100%;
    height: 100%;
    object-fit: cover;
}
@media (max-width:991px) {
    .about-us__content-wrapper{
        width: 100%;
    }
    .about-us{
        height: fit-content;
        min-height: 100vh;
        padding: 40px 0;
    }
    .about-us__img{
        margin-top: 50px;
        height: 500px;
    }
}   
@media (max-width:500px) {
    .about-us__img{
        height: 300px;
    }
}   