.swiper-about-us{
    position: relative;
    overflow: hidden;
}
.swiper-about-us__button{
    position:absolute;
    background-color:transparent;
    inset: 0;
    z-index: 2;
}
.swiper-about-us__wrapper{

}
.swiper-about-us__image{
    width: 100%;
    aspect-ratio: 1;
    /* height: 174px; */
}
.swiper-about-us__time{
    position: absolute;
    top:5%;
    left: 5%;
    background-color: #000000;
    color:white;
    padding:7.2px 12.5px;
    font-family: 'PoppingsMedium';
    font-size: 10.27px;
}
.swiper-about-us__name-wrapper{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width:100%;
    padding:10px;
    background-color: #00000099;
    color:white;
    text-align: center;
    font-size: 18px;
    font-family: 'PoppingsMedium';
    transition: var(--transition);
    transform: translateY(100%);
}
.swiper-about-us:hover .swiper-about-us__name-wrapper{
    transform: translateY(0%);
}
.swiper-about-us__name{
    color:white;
    font-size:15px;
}
.swiper-about-us__description{
    color:white;
    font-size: 10px;
}
@media (max-width:500px) {
    .swiper-about-us__description{
        display: none;
    }
    .swiper-about-us__name{
        font-size:13px;
    }
}