.header{
    /* padding-top: 100px; */
    height:100vh;
    background-color: #000000;
    position: relative;
}
.header__slider-controls-wrapper{
    position:absolute;
    right:100px;
    bottom: 50px;
    display: flex;
    align-items: center;
    gap:6px;
    z-index: 2;
}
.header__slider-control-wrapper{
    background-color: transparent;
    color:#C3C3C3;
    font-size: 10px;
}
.header__slider-control-wrapper--active{
    color:white;
}
.header__slider-control-arrow path{
    fill:white;
}
@media (max-width:768px) {
    .header__slider-controls-wrapper{
        /* right:40px */
        display: none;
    }
}