.navbar{
    position: fixed;
    inset:0 ;
    bottom: initial;
    padding:10px 0 ;
    display:flex;
    flex-direction: column;
    background-color: transparent;
    /* background-color: #000000; */
    /* margin-bottom: 35px; */
    z-index: 100;
    transition: var(--transition);
}
.navbar--scrolling{
    padding:10px 0;
    background-color: #000000;
}
.navbar__nav-logo{
    transition: var(--transition);
    width: 60px
}
.navbar--scrolling .navbar__nav-logo{
    width: 40px
}
.navbar__nav-list{
    display:flex;
    align-items:center;
    width:100%;
    gap:36px
}
.navbar__nav-logo-item{
    margin-right:auto
}
.navbar__nav-link{
    position: relative;
    color:white;
    font-size: 18px;
    transition: var(--transition);
}
.navbar__nav-link:hover{
    color:white;
}
.navbar__nav-link::before{
    content:'';
    width:0%;
    height:100%;
    background-color: var(--main-color);
    position: absolute;
    top: 0;
    left:0;
    z-index: 1;
    transition: var(--transition);
}
.navbar__nav-link:hover::before{
    width: 100% !important;
}
.navbar__nav-link-text{
    position: relative;
    z-index: 2;
    padding:2px;
    color:white;
    font-size: 18px;
}
.navbar__nav-menu{
    display: none;
    flex-direction: column;
    background-color: transparent;
    /* width:30px; */
    gap:5px
}
.navbar__nav-menu-span{
    width:20px;
    /* width:100%; */
    height: 1px;
    background-color: white;
    transition: var(--transition);
}
.navbar__nav-menu--active .navbar__nav-menu-span:nth-child(1){
    transform: rotate(45deg) translateX(50%);
}
.navbar__nav-menu--active .navbar__nav-menu-span:nth-child(2){
    opacity: 0;
}
.navbar__nav-menu--active .navbar__nav-menu-span:nth-child(3){
    transform: rotate(-45deg) translate(8px,1px);
    
}
@media (max-width:768px) {
    .navbar__nav-logo{
        transition: var(--transition);
        width: 40px
    }
    .navbar__nav-link{
        display: none;
    }
    .navbar__nav-menu{
        display: flex;
    }
}