.blog{
    padding: 100px 0 20px;
}
.blog__title{
    font-size: 44px;
    color:white;
    font-family: 'PoppinsSemiBold';
    margin-bottom: 22px;
}
.blog__img{
    width: 100%;
    height:529px;
    margin-bottom: 35px;
}
.blog__features-wrapper{
    display: flex;
    align-items: center;
    gap:30px;
    margin-bottom: 15px;
}
.blog__feature-wrapper{
    display: flex;
    align-items: center;
    gap:3px
}
.blog__feature-text{
    font-size: 9px;
    line-height: 0;
    color:white;
    font-family: 'PoppinsMedium';
}
.blog__description{
    color:white;
    font-family: 'PoppinsMedium';
    font-size: 16px;
    margin-bottom: 32px;
    line-height:29.12px;
}

.blog__side-wrapper{
    padding:40px;
    border: 1px solid white;
    width: 100%;
    /* height: 100%; */
}
.blog__side-img{
    width: 100%;
    height:141px;
    margin-bottom: 22px;
}
.blog__side-content-wrapper{
    margin-bottom: 38px;
}
.blog__side-content-wrapper:last-of-type{
    margin-bottom: 0px;
}
.blog__side-title{
    color: white;
    font-size: 20px;
    font-family: 'PoppinsSemiBold';
    margin-bottom: 15px;
}
.blog__side-description{
    font-size: 12px;
    color:#B9B9B9;
    line-height: 14.52px;
}
.blog__side-list{
    display: flex;
    flex-direction: column;
    gap:16px
}
.blog__side-list-item{
    width: 100%;
}
.blog__side-list-link{
    color: #B9B9B9;
    font-size: 12px;
    font-family: 'PoppinsMedium';
    width: 100%;
    display: flex;
    padding-bottom: 4px;
    border-bottom: 1px solid #B9B9B9
}
.blog__side-list-link:hover{
    color:var(--main-color);
    border-bottom: 1px solid var(--main-color)
}
.blog__side-images-wrapper{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap:20px 12px
}
.blog__side-images-img{
    width: 100%;
    height: 84px;
}
.blog__side-tags-wrapper{
    display: flex;
    flex-wrap: wrap;
    gap:12px;
}
.blog__side-tag{
    padding: 10px;
    background-color: #4B4B4B;
    font-size: 12px;
    color:#B9B9B9;
    transition: var(--transition);
}
.blog__side-tag:hover{
    color:var(--main-color);
}

.blog__form-title{
    color:white;
    font-size: 21px;
    font-family: 'PoppinsSemiBold';
    margin-bottom: 35px;
}
.blog__form-inputs-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap:50px
}
.blog__form-input-wrapper{
    position: relative;
    margin-bottom: 40px;
    width:100%;
}
.blog__form-input{
    border: none;
    outline: none;
    color:white;
    font-size: 11px;
    background-color: transparent;
    border-bottom: 1px solid white;
    height:30px;
    width:100%;

}
.blog__form-input-wrapper::before{
    content: '';
    bottom: 0;
    left: 0;
    right: 0;
    height:1px;
    width: 0%;
    background-color: var(--main-color);
    transition: var(--transition);
    position: absolute;
}
.blog__form-input-wrapper:hover::before{
    width: 100%;
}
.blog__form-label{
    position: absolute;
    top: 0;
    left:0px;
    color:white;
    transition: var(--transition);
    
}
.blog__form-input:focus ~ .blog__form-label ,.blog__form-input:not(:placeholder-shown) ~ .blog__form-label{
    transform:translateY(-100%)
}
.blog__form-input:focus ~ .blog__form-label ,.blog__form-input:not(:placeholder-shown) ~ .blog__form-label{
    transform:translateY(-100%)
}
.blog__form-submit{
    position: relative;
    font-size: 27.54px;
    background-color: transparent;
    color:white;
    border:none;
    outline: none;
    transition: var(--transition);
}
.blog__form-submit::before{
    content:'';
    width:20%;
    height:100%;
    background-color: var(--main-color);
    position: absolute;
    top: 0;
    left:0;
    z-index: 1;
    transition: var(--transition);
}
.blog__form-submit:hover::before{
    width: 100% !important;
}
.blog__form-submit-text{
    position: relative;
    z-index: 2;
}

@media (max-width:991px) {
    .blog{
        padding: 80px 0;
    }
    .blog__title{
        font-size: 34px;
        margin-bottom: 12px;
    }
}   

@media (max-width:500px) {
    .blog__title{
        font-size: 24px;
        margin-bottom: 12px;
    }
    .blog__img{
        height:300px;
        margin-bottom: 25px;
    }
}   