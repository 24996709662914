.swiper-header{
    position: relative;
    padding-top: 100px;
}
.swiper-header--bg{
    background:url('/src/assets/imgs/HeaderBg1.jpg');
    position: absolute;
    inset: 0;
    z-index: -1;
    filter: blur(10px);
}
.swiper-header__video-wrapper{
    /* height: 70vh; */
    /* width: auto;  */
    /* height: auto; */
    position: relative;
    z-index: 1;
}

.swiper-header__video video{
    object-fit: cover;
    /* width: auto;  */
    /* height: auto; */
}
.swiper-header__info-wrapper{
    display: flex;
    align-items: center;
}

.swiper-header__bottom-wrapper{
    display: flex;
    align-items: center;
}
.swiper-header__info-number{
    font-size: 80px;
    font-family: 'PoppinsSemiBold';
    color: #FFFFFF4D;
    /* opacity: .1; */
}
.swiper-header__info-title-wrapper{
    display: flex;
    flex-direction: column;
    transform: translateX(-40px);
    gap:2px;
}
.swiper-header__info-title-header{
    line-height: 0;
    color: #9f9f9f;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
}
.swiper-header__info-title{
    color:white;
    font-size: 30px;
    margin: 0;
    font-family: 'PoppinsMedium';
}
.swiper-header__view-film{
    position: relative;
    font-size: 27.54px;
    background-color: transparent;
    color:white;
    border:none;
    outline: none;
    transition: var(--transition);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.swiper-header__view-film::before{
    content:'';
    width:20%;
    height:100%;
    background-color: var(--main-color);
    position: absolute;
    top: 0;
    left:0;
    z-index: 1;
    transition: var(--transition);
}
.swiper-header__view-film:hover::before{
    width: 100% !important;
}
.swiper-header__view-film-text{
    position: relative;
    z-index: 2;
    display:flex;
    align-items: center;
    gap:10px;
    font-size: 20px;
}
.swiper-header__view-film-icon path{
    fill:white
}
.swiper-header__video-content-wrapper{
    position: absolute;
    inset: 0;
    z-index: 1;
    background:url('/src/assets/imgs/swiperHeaderGridBG.png');
    background-size: cover;
    display:flex;
}
.swiper-header__video-content-title-wrapper{
    /* pos */
    width:450px;
    border-radius: 30px;
    margin:auto 0 30px 30px;
    padding:33px;
    background-color: #000000B2;
}
.swiper-header__video-content-title{
    font-size:16px;
    color:var(--main-color);
}
.swiper-header__video-content-welcome{
    font-size:32px;
    color:white;
    margin-bottom: 16px;
    /* font-size: ; */
}
.swiper-header__video-content-description{
    color:white;
    font-size:12px;
}
@media (max-width:991px) {
    .swiper-header{
        padding-top: 80px;
    }
}
@media (max-width:768px) {
    .swiper-header{
        padding-top: 70px;
    }
}
@media (max-width:500px) {
    .swiper-header__video-content-title-wrapper{
        width: 450px;
        border-radius: 10px;
        margin: auto 10px 10px;
        padding: 10px;
        background-color: #000000B2;
    }
    .swiper-header__video-content-welcome{
        font-size: 22px;
        margin-bottom: 6px;
    }
    .swiper-header__video-content-title{
        font-size: 14px;
    }
}