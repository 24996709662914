.blogs{
    margin-bottom: 60px;
    margin-top: 100px;
}
.blogs__title{
    position: relative;
    font-size: 40px;
    color:white;
    margin-bottom:40px;
    text-align:center;
    padding-bottom: 20px;
}
.blogs__title::after{
    content: "";
    position: absolute;
    height: 1px;
    width: 50px;
    background-color: var(--main-color);
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}
.blogs__wrapper{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap:70px 110px
}
@media (max-width:991px) {
    .blogs__wrapper{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap:50px 70px;
    }
}

@media (max-width:500px) {
    .blogs__wrapper{
        grid-template-columns: repeat(1,1fr);
        grid-gap:20px;
    }
}