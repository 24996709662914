.section-title{
    position: absolute;
    top:50%;
    left:-70px;
    color:white;
    font-size: 18px;
    /* margin-right: 30px; */
    transform: translateY(-50%) rotate(270deg);
    display: flex;
    align-items: center;
    gap: 10px;
}
.section-title::after{
    content: '';
    height:2px;
    width:100px;
    background-color: #ED1C26;
    display: flex;
}
@media (max-width:991px) {
    .section-title{
        display: none;
    }
}