body{
    padding-top: 0;
}
/* ::-webkit-scrollbar{
    width: 0
} */
.home{
    height: 100vh;
    overflow-y: hidden;
}
.home__navigate-wrapper-list{
    display: flex;
    flex-direction: column;
    gap:23px;
    position:fixed;
    z-index: 111;
    right:40px;
    top:50%;
    transform:translateY(-50%)
}
.home__navigate-wrapper-item{

}
.home__navigate-wrapper-link{
    width: 12px;
    height: 12px;
    background-color: #FFFFFF66;
    border-radius: 50%;
    transition: var(--transition);
    cursor: pointer;
}
.home__navigate-wrapper-link:hover{
    background-color: var(--main-color);
}
.home__navigate-wrapper-link--active{
    background-color: var(--main-color);
}
.home__section-wrapper{
    transition: var(--transition);
    /* transition-delay: .3s; */
    transition-duration: .8s;
    transition-timing-function: ease;
    /* transform: translateY(-100%); */
    position: fixed;
    inset: 0;
}
.home__section-wrapper--active{
    transform: translateY(0);
    /* z-index: 10; */
}
.home__section-wrapper--next{
    transform: translateY(50vh);
    /* z-index: 10!important; */
}
.home__section-wrapper--prev{
    transform: translateY(-100vh);
    /* z-index: 10!important; */
}
.home__section-wrapper--index-1{
    z-index:20
}
.home__section-wrapper--index-2{
    z-index:19
}
.home__section-wrapper--index-3{
    z-index:18
}
.home__section-wrapper--index-4{
    z-index:17
}
.home__section-wrapper--index-5{
    z-index:16
}
/* .home-wrapper{
    scroll-snap-type: y mandatory;
    height: 100vh;
    overflow-y: scroll
} */

@media (max-width:991px) {
    .home{
        height: initial;
        overflow-y: initial;
    }
    .home__section-wrapper{
        position: initial;
    }
    .home__section-wrapper--next{
        transform: translateY(0);
        /* z-index: 10!important; */
    }
    .home__section-wrapper--prev{
        transform: translateY(0);
        /* z-index: 10!important; */
    }
    .home__navigate-wrapper-list{
        display: none;
    }
}