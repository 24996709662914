.contact-us{
    /* margin-bottom: 60px; */
    background:url('/src/assets/imgs/contactUsBG.png') ,#000000;
    background-size: 101% 100%;
    padding:90px 0 0;
    position: relative;
    height: 100vh;
    /* scroll-snap-align: start;
    height:100vh */
}
.contact-us__content-wrapper{
    width: 100%;
    display:flex;
    flex-direction: column;
}
.contact-us__title{
    font-size: 38px;
    color:white;
    font-family: 'PoppinsSemiBold';
    margin-bottom: 30px;
}
.contact-us__contacts-wrapper{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap:45px 10px;
    margin-bottom:60px
}
.contact-us__contact-wrapper{
    display: flex;
    align-items: center;
    gap:20px;
    background-color: transparent;
    padding:0;
}
.contact-us__contact-icon{

}
.contact-us__contact-info-wrapper{
    display: flex;
    flex-direction: column;
    gap:1px;
}
.contact-us__contact-info-label{
    color:#B9B9B9;
    font-size: 13px;
    margin: 0;
    text-align: start;
}
.contact-us__contact-info-text{
    font-size: 15px;
    color:white;
    margin: 0;
    text-align: start;
}
.contact-us__form-title{
    color:white;
    font-size: 21px;
    font-family: 'PoppinsSemiBold';
    margin-bottom: 35px;
}
.contact-us__form-inputs-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap:50px
}
.contact-us__form-input-wrapper{
    position: relative;
    margin-bottom: 40px;
    width:100%;
}
.contact-us__form-input{
    border: none;
    outline: none;
    color:white;
    font-size: 11px;
    background-color: transparent;
    border-bottom: 1px solid white;
    height:30px;
    width:100%;
}
.contact-us__form-input-wrapper::before{
    content: '';
    bottom: 0;
    left: 0;
    right: 0;
    height:1px;
    width: 0%;
    background-color: var(--main-color);
    transition: var(--transition);
    position: absolute;
}
.contact-us__form-input-wrapper:hover::before{
    width: 100%;
}
.contact-us__form-label{
    position: absolute;
    top: 0;
    left:0px;
    color:white;
    transition: var(--transition);
}
.contact-us__form-input:focus ~ .contact-us__form-label ,.contact-us__form-input:not(:placeholder-shown) ~ .contact-us__form-label{
    transform:translateY(-100%)
}
.contact-us__form-input:focus ~ .contact-us__form-label ,.contact-us__form-input:not(:placeholder-shown) ~ .contact-us__form-label{
    transform:translateY(-100%)
}
.contact-us__form-submit{
    position: relative;
    font-size: 27.54px;
    background-color: transparent;
    color:white;
    border:none;
    outline: none;
    transition: var(--transition);
}
.contact-us__form-submit::before{
    content:'';
    width:20%;
    height:100%;
    background-color: var(--main-color);
    position: absolute;
    top: 0;
    left:0;
    z-index: 1;
    transition: var(--transition);
}
.contact-us__form-submit:hover::before{
    width: 100% !important;
}
.contact-us__form-submit-text{
    position: relative;
    z-index: 2;
}

.contact-us__map{
    width: 100% !important;
    height: 100% !important;
}
.about-us__img{
    width:100%;
    height: 100%;
    object-fit: cover;
}
@media (max-width:991px) {
    .contact-us{
        height: fit-content;
        min-height: 100vh;
        padding: 40px 0;
    }
    .contact-us__content-wrapper{
        width:100%
    }
    .contact-us__contacts-wrapper{
        display: grid;
        grid-template-columns: 1fr ;
    }
    .contact-us__map{
        height: 300px!important;
        margin-top: 20px;
    }
    .about-us__img{
        margin-top: 50px;
        height: 500px;
    }
}
@media (max-width:500px) {
    .about-us__img{
        height: 300px;
    }
}   