.blog-card{
    position: relative;
    transition: var(--transition);
}
.blog-card:hover{
    transform: translateY(-10px);
}
.blog-card__link{
    position:absolute;
    inset: 0;
    z-index: 2;
}
.blog-card__wrapper{
    display:flex;
    flex-direction:column
}
.blog-card__image{
    width:100%;
    height:214px;
    /* aspect-ratio: 1; */
    margin-bottom: 23px;
}
.blog-card__content-wrapper{
    
}
.blog-card__content-title{
    font-size: 23px;
    line-height: 34.5px;
    color:white;
    font-family: 'PoppinsMedium';
    margin-bottom: 10px;
}
.blog-card__features-wrapper{
    display: flex;
    align-items: center;
    gap:30px;
    margin-bottom: 23px;
}
.blog-card__feature-wrapper{
    display: flex;
    align-items: center;
    gap:3px
}
.blog-card__feature-icon{

}
.blog-card__feature-text{
    font-size: 9px;
    line-height: 0;
    color:white;
    font-family: 'PoppinsMedium';
}
.blog-card__description{
    color:white;
    font-size: 15px;
    line-height: 22.5px;
    font-family: 'PoppinsLight';
    height: 115px;
    text-overflow: ellipsis;
}