
.sidebar{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 99;
    display: flex;
    width: 0;
    transition: var(--transition);
    transition-duration: .1s;
    overflow: hidden;
    height: 100%;
    opacity: 0;
    /* border-radius: 50%; */
    /* border-top-left-radius: 0; */
}
.sidebar--open{
    height: 100%;
    width: 100%;
    border-radius: 0;
    width: 100%;
    opacity: 1;
}
.sidebar__logo{
    width: 87px;
    height: 25px;
    object-fit: contain;

}
.sidebar__close-button{
    background-color: transparent;
    font-size: 22px;
    color: white;
    font-family: 'PoppinsBold';
}
.sidebar__content{
    width: 70%;
    background-color: #000000;
    border-right:1px solid #eee;
    height: 100%;
    padding: 60px 25px 30px;
    overflow-y: auto;
}
.sidebar__profile-cont{
    padding: 0px 30px;
    border-bottom: 1px solid #0000001F;
}
.sidebar__overlayer{
    width: 30%;
    background-color: transparent;
    height: 100%;
    opacity: .8;
}
.sidebar__profile-img{
    width: 70px;
    height: 70px;
    margin-bottom: 15px;
    border: 1px solid var(--text-color-dark);
    border-radius: 50%;
    padding: 6px;
    object-fit: contain;
}
.sidebar__profile-name{
    color: var(--text-color-dark);
    font-family: 'PoppinsMedium';
}
.sidebar__nav-item{
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    gap: 10px;
    padding: 16px 0px;
    font-size: 20px;
    font-family: 'PoppinsMedium';
    /* justify-content: center; */
}
.sidebar__nav-item a{
    /* width: 100%; */
    /* padding: 20px 40px; */
    display:flex;
    align-items: center;
    transition:var(--transition);
    color: white;
}
.sidebar-menu-link{
    background-color:transparent;
    color: white;
}
.sidebar__nav-item:hover .sidebar__nav-link{
    color: white;
}
.sidebar__nav-item a.active{
    /* background: linear-gradient(270deg, rgb(250, 251, 191) 12%, rgba(182,138,53,1) 77%); */
    background: var(--main-color);
}
.sidebar__nav-item a.active{
    color: white;
}
.sidebar__nav-list{
    border-top: 1px solid #0000001F;
    margin-bottom: 100px;
}
.sidebar__nav-list .sidebar__nav-item:last-of-type{
    border-bottom: 1px solid #0000001F;
}
.sidebar__nav-link{
    color:var(--text-color-dark);
    transition: var(--transition);
}
.sidebar__logOut-cont{
    padding:30px;
}
.sidebar__logOut{
    background-color: transparent;
    border: 0;
    color: #CE2424;
    margin: auto;
    cursor: pointer;
}
.sidebar__logOut-icon{
    margin-right: 5px;
}
.sidebar__content::-webkit-scrollbar{
    width: 8px;
}

.sidebar__contact-item{
    background-color:var(--main-color);
    display: flex;
    align-items:center;
    border-radius: 5px;
    justify-content: flex-start;
    padding: 0;
    /* height: 47px; */
    /* width: 270px; */
    direction: ltr;
    gap: 10px;
}
.sidebar__contact-text{
    color: white;
    font-size: 13px;
    /* margin-left: 10px; */
}
/* body[dir='rtl'] .sidebar__contact-text{
    margin-left: 0px;
    margin-right: 10px;
} */
.sidebar__language-span{
    font-size: 19.17px;
    margin-bottom: 16px;
    font-family: 'PoppinsBold';
    color: white;
    margin-top: 20px;
}
.sidebar__nav-link-icon--rotate{
    transform: rotate(270deg);
}
body[dir='rtl'] .sidebar__nav-link-icon--rotate{
    transform: rotate(-270deg);
}
body[dir='rtl'] .sidebar__close-icon{
    transform: rotate(180deg);
}
.sidebar__content-language{
    --btn-bg-color:white;
    --btn-color:var(--main-color);
    /* margin: auto; */
}
.sidebar__logo{
    height: 59px;
    width: 200px;
    /* width: -moz-fit-content; */
    /* width: fit-content; */
    object-fit: contain;
}

.sidebar__nav-link{
    position: relative;
    color:white;
    font-size: 18px;
    transition: var(--transition);
    padding:4px
}
.sidebar__nav-link:hover{
    color:white;
}
.sidebar__nav-link::before{
    content:'';
    width:0%;
    height:100%;
    background-color: var(--main-color);
    position: absolute;
    top: 0;
    left:0;
    z-index: 1;
    transition: var(--transition);
}
/* .sidebar__nav-link:hover::before{
    width: 100% !important;
} */

@media(min-width:1200px){
    .sidebar{
        width:0!important;
    }
}
@media (max-width:450px) {
    .sidebar__brand-items-wrapper{
        grid-template-columns: repeat(2,1fr);
    }
    .sidebar__overlayer{
        width: 15%;
    }
    .sidebar__content{
        width: 85%;
    }
}